<template>
  <div>
    <div class="div_center">
      <div class="center" style="height: 520px;padding: 150px 0">
        <div style="font-size: 30px">校企技术供需匹配系统</div>
        <div style="font-size: 50px;margin-top: 20px;margin-bottom: 20px"><span style="color: white">帮助科技型企业</span>找专利、找成果、找专家！
        </div>
        <div style="width: 340px;font-size: 24px">
          <span style="color: white;padding-left: 4px">免费</span>下载科创资源匹配报告！
        </div>
      </div>
    </div>
    <div style="position: relative">
      <div class="lipu" :style="{'width':border_width}"></div>
      <div class="div_center_com" style="height: 450px">
        <div class="center" style="height: 450px">
          <div
            style="padding-top: 90px;padding-bottom: 60px;font-size: 28px;color: black;text-align: center;overflow: hidden">
            科创资源
          </div>
          <div style="display: flex;justify-content: space-between">
            <div style="height: 190px;width: 390px;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);padding-left: 40px;position: relative">
              <div style="padding: 30px 0;height: 30px;margin-bottom: 30px" class="small_box_parent">
                <div class="small_box">专利</div> <div></div> <div></div> <div></div> <div></div>
              </div>
              <div style="color: black;font-size:18px;line-height: 18px">
                全国 <span>2688</span>所高校高校、<span>270</span><span>w</span><br></br>专利数据
              </div>
              <div style="font-weight: bolder;font-size:20px;position: absolute;bottom: 10px">....</div>
            </div>
            <div style="height: 190px;width: 390px;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);padding-left: 40px;position: relative">
              <div style="padding: 30px 0;height: 30px;margin-bottom: 30px" class="small_box_parent">
                <div class="small_box">专家</div> <div></div> <div></div> <div></div> <div></div>
              </div>
              <div style="color: black;font-size:18px;line-height: 18px">
                全国 <span>40w</span>高校科研专家
              </div>
              <div style="font-weight: bolder;font-size:20px;position: absolute;bottom: 10px">....</div>
            </div>
            <div style="height: 190px;width: 390px;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);padding-left: 40px;position: relative">
              <div style="padding: 30px 0;height: 30px;margin-bottom: 30px" class="small_box_parent">
                <div class="small_box">成果</div> <div></div> <div></div> <div></div> <div></div>
              </div>
              <div style="color: black;font-size:18px;line-height: 18px">
                全国 <span>80w</span>优质科技成果

              </div>
              <div style="font-weight: bolder;font-size:20px;position: absolute;bottom: 10px">....</div>
            </div>
          </div>
        </div>
      </div>

      <div class="div_center_com ljty" >
        <div style="height: 100%;padding: 50px 0">
          <div class="center">
            <div @click="goQXT()" style="cursor:pointer;width: 183px;margin-top:45px;margin-right:260px;height: 46px;color:white!important;font-size:20px;font-weight:bold;float:right;background-color:  rgb(24, 123, 255);display: flex;align-items: center;justify-content: center">
              立即体验
            </div>
            <div style="font-size: 26px;color: white;font-weight: bold;margin-left: 70px">
              盘活高校科技资源，助推企业科技发展
            </div>
            <div style="margin-left: 110px;font-size: 32px;color: white;font-weight: bold;margin-top: 20px">
              高效、免费、丰富、及时
            </div>

          </div>

        </div>
      </div>
      <div class="div_center_com" style="height: 495px">
        <div class="center" style="height: 100%;display: flex;align-items: center;">
          <img :src="cpjj" alt="">
          <div style="height: 100%;">
            <div style="height: 30%;font-size: 28px;color: black;font-weight: bold;margin-left: 100px;padding-top: 50px">产品简介</div>
            <div style="height: 70%;width:600px;line-height:30px;font-size: 14px;color: black;font-weight: bold;margin-left: 150px;padding-top: 50px">企校通是中高自主研发的线上校企技术供需匹配系统，基于中国2688所高校海量科研成果数据及高校专家智库，以企业需求为导向，利用大数据、机器学习技术，自动匹配企业领域、产品、技术相关的专利、科研成果、科研专家，并生成标准化的匹配报告，提升企业技术对接的效率，为企业科技发展注入新动力！</div>
          </div>
        </div>
      </div>
      <div class="div_center_com bottom_moren" style="height: 540px;position: relative">
        <div style="position: absolute;right: 0;top:0;background-color:rgba(12,28,101,.7);height: 540px" :style="{'width':border_width}"></div>
        <div class="center" style="height: 540px;display: flex;position: relative" >
          <div style="height: 160px;width: 160px;border-radius: 50%;position: absolute;top: 50%;left:52%;transform: translate(-50%,-50%);background-color: white;color: black;text-align: center;font-size: 24px;padding-top: 40px">
            成功案例 <br>
            -->

          </div>
          <div style="width: 620px;height: 100%">

          </div>
          <div style="height:100%;background-color:rgba(12,28,101,.7);width: 580px;">
            <div style="font-size: 40px;color: white;margin-left: 150px;margin-top: 130px;margin-bottom: 80px">某企业</div>
            <div style="color: white;font-size: 14px;width: 450px;margin-left: 150px;line-height: 30px">
              某大型电信行业龙头企业，通过技术研发领域的特定关键词，从企校通生成科创资源的匹配报告，快速锚定技术领域相关的专利、成果、科研专家，主动开展针对性的技术交流、产学研对接、横向合作，大大降低了文献阅读、文献检索、经验传承等传统模式下技术挖掘的工作量。
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  import cpjj from '@/assets/image/index/cpjj.png'
  import {mapGetters} from "vuex";
  export default {
    name: "QiIndex",
    computed:{
      ...mapGetters({
        token: 'userStore/token',
        userName: 'userStore/userName',
        userId: 'userStore/userId',
        enterpriseState: 'userStore/enterpriseState'
      }),
      border_width(){
        return (document.body.clientWidth - 1200) / 2 + 'px'
      },
      border_width2(){
        return (document.body.clientWidth - 1200) / 2 + 600 + 'px'
      },
      margin_left(){
        return (document.body.clientWidth - 1200) / 2 + 'px'
      }
    },
    data(){
      return {
        cpjj:cpjj
      }
    },
    methods:{
      goQXT(){
        this.$router.push({path:'/qxt'})
        // if(this.enterpriseState !== 'CERTIFIED'){
        //   this.$router.push({
        //     path:'/enterprise/EnterpriseCertification'
        //   })
        // }else{
        //   this.$router.push({
        //     path:'/enterprise/enterprise-school-connection'
        //   })
        // }

      }
    }
  }
</script>

<style scoped lang="scss">
  .bottom_moren {
    background-image: url("~@/assets/image/index/bottommoren.png");
    background-position: center;
    background-size: 100% 100%;
  }
  @media screen and (max-width: 1800px){
    .lipu {
      display: none;
    }
  }
  .lipu {
    background-image: url("~@/assets/image/index/exin.png");
    position: absolute;
    z-index: 100;
    bottom: 0;
    right: 0;
    height: 1500px;
  ;
  }
  .ljty {
    height: 209px;
    background-image: url("~@/assets/image/index/ljty.png");
    background-size: 100% 209px;
  }
  .small_box_parent {
    display: flex;
  }
  .small_box_parent div {
    margin-right: 7px;
    width: 5px;
    height: 30px;
  }
  .small_box_parent div:nth-child(2){
    background-color: rgba(0,89,234,1);
  }
  .small_box_parent div:nth-child(3){
    background-color: rgba(0,89,234,.8);
  }
  .small_box_parent div:nth-child(4){
    background-color: rgba(0,89,234,.6);
  }
  .small_box_parent div:nth-child(5){
    background-color: rgba(0,89,234,.4);
  }
  .small_box {
    height: 30px;
    width: 70px!important;
    background-color: #0059ea;
    font-size: 18px;
    font-weight: bold;
    color: white!important;
    line-height: 30px;
    padding-left: 10px;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
    & div {
      color: #50baf7;
    }
  }

  .div_center_com {
    width: 100%;
  }

  .div_center {
    width: 100%;
    height: 520px;
    background-position: center;
    background-image: url("~@/assets/image/index/qi.png");
    background-repeat: no-repeat;
    background-size: 100% 520px;
  }

</style>
